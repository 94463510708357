import {
  setDefaults,
  //   setLanguage,
  //   setRegion,
  fromAddress,
  fromLatLng
  //   fromPlaceId,
  //   setLocationType,
  //   geocode,
  //   RequestType
} from 'react-geocode';
import Toast from 'components/toast';

const configDefaultSetting = () => {
  setDefaults({
    key: process.env.REACT_APP_GEO_CODE_SECRETS, // Your API key here.
    language: 'en', // Default language for responses.
    region: 'es' // Default region for responses.
  });
};

const getLocation = () => {
  configDefaultSetting();
  async function locationFromLatLong(lat, long) {
    let locationResult = await fromLatLng(lat, long)
      .then(({ results }) => {
        let [firstLocation] = results;

        return firstLocation;
      })
      .catch(error => {
        console.error(error);
        Toast({ type: 'locationError' })();
        return;
      });
    return locationResult;
  }
  async function locationFromAddress(Address) {
    let locationResult = await fromAddress(Address)
      .then(({ results }) => {
        // const { lat, lng } = results[0].geometry.location;
        // let [firstLocation] = results;
        return results;
      })
      .catch(error => {
        console.error(error, 'This is error');
        return [];
      });
    return locationResult;
  }

  return { locationFromLatLong, locationFromAddress };
};

export const FormAddress = result => {
  let addressOptionResult = [];
  result?.forEach(value => {
    let addressComponents = value?.address_components;
    // Initialize variables for address parts
    let addressLine1 = '';
    // let addressLine2 = '';
    // let city = '';
    // let state = '';
    // let postalCode = '';
    addressComponents?.forEach(component => {
      // const types = component.types;

      // Check for street number and route for address line 1
      //   if (types.includes('street_number')) {
      addressLine1 += component.long_name + ' '; // Add street number
      //   }
      //   if (types.includes('route')) {
      //     addressLine1 += component.long_name; // Add street name
      //   }

      // Check for sublocality for address line 2
      //   if (
      //     types.includes('sublocality') ||
      //     types.includes('neighborhood')
      //   ) {
      //     addressLine2 += ' ' + component.long_name; // Add sublocality/neighborhood
      //   }

      // Check for city
      //   if (types.includes('locality')) {
      //     city = component.long_name;
      //   }

      // Check for administrative area (state)
      //   if (types.includes('administrative_area_level_1')) {
      //     state = component.long_name;
      //   }

      // Check for postal code
      //   if (types.includes('postal_code')) {
      //     postalCode = component.long_name;
      //   }
    });
    addressOptionResult.push({
      label: `${addressLine1}`,
      value: `${addressLine1}`,
      geoLocation: value?.geometry
    });
  });
  return addressOptionResult;
};

export default getLocation;
