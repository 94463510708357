// import { fontSize, height } from '@mui/system';
import styled, { createGlobalStyle } from 'styled-components';
import { Select } from 'antd';

export const allBrandInputStyle = {
  '& .MuiInputBase-input::placeholder': {
    fontStyle: 'italic',
    fontSize: '1rem'
  },
  borderRadius: '16px',
  // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: 'none',
  '& fieldset': {
    border: 'none'
  },
  '& .MuiInputBase-input': {
    height: '10px', // Default width for small screens
    '@media (min-width: 768px)': {
      height: '14px' // Adjust width for screens above md (768px)
    }
  }
};

export const AccordianStyles = (conditionWithPrices, isExpanded, index) => {
  return {
    boxShadow: 'none', // Remove shadow
    borderRadius: '10px !important', // Ensure rounded corners
    border: `${isExpanded ? '2px solid rgb(4, 42, 103)' : 'none'}`,
    marginBottom: index === conditionWithPrices?.length - 1 ? '0' : '16px', // No margin for the last item
    '&:before': {
      display: 'none' // Hide the default border line between accordions
    }
  };
};
export const AccordianSummary = {
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
};
export const ContactFormStyles = {
  width: '100%',
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 100px #fff inset',
    WebkitTextFillColor: '#000'
  },
  '& input:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 100px #fff inset',
    WebkitTextFillColor: '#000'
  },

  // Set the background color on focus or when an option is selected
  '& .Mui-focused .MuiOutlinedInput-root': {
    backgroundColor: 'white' // Keep background white on focus
  },

  '& .MuiInputBase-input': {
    // backgroundColor: 'red',
    borderRadius: '10px !important', // Ensure input has rounded corners
    backgroundColor: 'white !important' // Force white background to prevent blue highlight
  },

  // Handle the background color when input is focused or has a value
  '& .Mui-focused .MuiInputBase-input': {
    backgroundColor: 'white' // Keep background white when focused or value is selected
  },

  // General input styles to ensure it always has a white background
  '& input': {
    height: '10px !important', // Keep background white on focus

    backgroundColor: 'white' // Ensure the input has a white background
  },

  // Apply background color and border-radius to the input container
  '& .MuiInputBase-root': {
    borderRadius: '10px', // Rounded corners for the container
    backgroundColor: 'white' // Keep the background white for the container
  },

  // Style for the actual input text field
  // '& .MuiInputBase-input': {
  //   borderRadius: '10px !important', // Ensure input has rounded corners
  //   backgroundColor: 'white !important' // Force white background to prevent blue highlight
  // },

  // Remove the default border around the input field
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none' // No default border
  },

  // Custom error state handling with red border
  '& .Mui-error': {
    border: 'none !important', // Remove existing borders on error
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red' // Red border when there's an error
    }
  }
};

export const ModelFormStyles = {
  width: '100%',
  '& input:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 100px #fff inset',
    WebkitTextFillColor: '#000'
  },
  '& input:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 100px #fff inset',
    WebkitTextFillColor: '#000'
  },

  // Set the background color on focus or when an option is selected
  '& .Mui-focused .MuiOutlinedInput-root': {
    backgroundColor: 'white'
  },

  // Handle the background color when input is focused or has a value
  '& .Mui-focused .MuiInputBase-input': {
    backgroundColor: 'white'
  },

  // General input styles to ensure it always has a white background
  '& input': {
    height: '30px !important', // Adjust the height here to make it smaller
    backgroundColor: 'white',
    padding: '0px !important' // Ensure no padding
  },

  // Apply background color and border-radius to the input container
  '& .MuiInputBase-root': {
    borderRadius: '10px', // Rounded corners for the container
    backgroundColor: 'white',
    padding: '4px 10px' // Reduce padding to make input smaller
  },

  // Style for the actual input text field
  '& .MuiInputBase-input': {
    borderRadius: '10px !important',
    backgroundColor: 'white !important',
    padding: '6px 12px', // Reduce padding to make input smaller
    fontSize: '0.875rem', // Adjust font size to fit the smaller height
    lineHeight: 1.2 // Adjust line height for smaller input
  },

  // Placeholder styling
  '& .MuiInputBase-input::placeholder': {
    fontSize: '0.8rem', // Font size for the placeholder
    color: '#908c8c' // Color for the placeholder
  },

  // Remove the default border around the input field
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none' // No default border
  },

  // Custom error state handling with red border
  '& .Mui-error': {
    border: 'none !important', // Remove existing borders on error
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red' // Red border when there's an error
    }
  }
};

export const hexagonStructureStyle = {
  width: '70px',
  height: '40px',
  backgroundColor: 'rgb(4, 42, 103)',
  position: 'relative',
  margin: '30px auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
    width: '0',
    borderLeft: '35px solid transparent',
    borderRight: '35px solid transparent'
  },
  '&:before': {
    bottom: '100%',
    borderBottom: '22.5px solid rgb(4, 42, 103)'
  },
  '&:after': {
    top: '100%',
    borderTop: '20.5px solid rgb(4, 42, 103)'
  }
};

export const StyleSelect = styled(Select)`
  width: 100% !important;
  height: 50px !important;

  .ant-select-selector {
    border-radius: 15px !important;
    background-color: white;
    border: 1px solid #ccc8c8 !important;
    display: flex;
    align-items: center;
    padding-right: 8px;
    box-shadow: none !important; /* Remove any default shadow */
  }
  .ant-select-selection-item {
    font-size: 1rem !important;
  }
  .ant-select-arrow {
    display: none !important;
  }

  /* Remove blue border on focus, hover, and active states */
  .ant-select-selector:hover,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-selector.ant-select-focused,
  .ant-select-focused .ant-select-selector {
    border: 1px solid #ccc8c8 !important; /* Your custom border color */
    box-shadow: none !important; /* Remove focus shadow */
  }
  .ant-select-dropdown {
    border: 1px solid red !important;
  }

  .suffix-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 8px;
  }
`;

export const GlobalSelectStyle = createGlobalStyle`
.ant-select-dropdown {
  border: 1px solid  rgb(4, 42, 103);
}
  .ant-select-item-option-content{
  font-size: 1rem !important;
  }
  .rc-virtual-list-holder-inner{
  background: rgb(242 245 249);
  }
`;

export const PickUpSelectStyle = isSelected => {
  return !isSelected
    ? {
        color: 'white', // Default color for the checkbox border when unchecked
        '&.Mui-checked': {
          color: 'rgb(4, 42, 103)' // Fill color for the checkbox background when checked
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          //   border: '2px solid white', // White border when checked
          borderRadius: '10px' // Optional: Slightly rounded corners
        },
        '& .MuiSvgIcon-root': {
          fill: 'rgb(4, 42, 103)' // Ensures the checkmark stays visible (white tick)
        }
      }
    : {
        '& .MuiSvgIcon-root': {
          border: '2px solid rgb(4, 42, 103)', // Custom border color for the icon
          borderRadius: '4px', // Optional: Slight rounding if desired
          fill: 'white' // Ensures the checkmark stays visible (white tick)
        },
        '&.MuiCheckbox-root': {
          border: 'none' // Removes the default border for the checkbox container
        },
        '&:not(.Mui-checked) .MuiSvgIcon-root': {
          backgroundColor: 'transparent' // Ensures the background is transparent when unchecked
        },
        '&.Mui-checked .MuiSvgIcon-root': {
          backgroundColor: 'rgb(4, 42, 103)' // Optional: Background when checked
        }
        // '& .MuiSvgIcon-root': {
        //   fill: 'white', // Ensures the checkmark stays visible (white tick)
        //   border: '2px solid rgb(4, 42, 103)'
        // }
      };
};

export const AutoCompleteStyles = {
  '& .MuiAutocomplete-root': {
    backgroundColor: 'white !important'
  },
  '& .MuiInputBase-input::placeholder': {
    // fontStyle: 'italic',
    fontSize: '1.2rem'
  },
  borderRadius: '16px',
  // boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: 'none',
  '& fieldset': {
    border: 'none'
  }
};

export const ContactFormAutoFillStyle = {
  width: '100%',
  borderRadius: '10px',
  '&.Mui-focused': {
    // backgroundColor: 'white', // Set the background color on focus
  },
  '& input': {
    backgroundColor: 'white', // Ensure the input itself has the white background
    height: '16px'
  },
  // '& .MuiAutocomplete-root':{
  //   borderRadius:"100px !important",

  // },
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    backgroundColor: 'white'
  },
  '& .MuiInputBase-input': {
    borderRadius: '10px !important',
    backgroundColor: 'white !important'
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .Mui-error': {
    border: 'none !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red'
    }
  }
};
