import Apitypes from 'api/constant';
import { BRAND_TYPE } from 'customHooks/index';

export const groupBrandsByLetter = (searchVal, brandData) => {
  const grouped = {};
  const alphabet = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );

  alphabet.forEach(letter => {
    grouped[letter] = [];
  });

  brandData.forEach(brand => {
    const firstLetter = brand.name[0].toUpperCase();
    if (grouped[firstLetter]) {
      if (searchVal?.length === 0) grouped[firstLetter].push(brand);
      else if (brand.name.toLowerCase().includes(searchVal.toLowerCase())) {
        grouped[firstLetter].push(brand);
      }
    }
  });

  for (const letter in grouped) {
    if (grouped[letter]?.length === 0) {
      delete grouped[letter];
    }
  }

  return grouped;
};

export const formImageUrl = S3_Path => {
  return `${process.env.REACT_APP_S3_BUCKET}${S3_Path}`;
};

export const currentLayoutToDisplay = (currentAllLayout, currentPath) => {
  let displayContent = currentAllLayout[currentPath.length - 1];
  if (displayContent) {
    return displayContent;
  }
  return <></>;
};

export const brandApiHelper = data => {
  let { categoryId, brandId, modelId } = data;

  switch (true) {
    case !!modelId:
      return {
        api: `${Apitypes.attribute}${modelId}`,
        type: BRAND_TYPE.attributesData
      };

    case !!brandId:
      return {
        api: `${Apitypes.brandModel}${categoryId}/${brandId}`,
        type: BRAND_TYPE.modelData
      };
    case !!categoryId:
      return {
        api: `${Apitypes.brandCategory}${categoryId}`,
        type: BRAND_TYPE.brandData
      };

    default:
      return;
  }
};

export const extractInnermostData = response => {
  let current = response;

  while (current && typeof current === 'object' && current.data) {
    if (Array.isArray(current.data)) {
      return current.data;
    }
    current = current.data;
  }

  return current;
};

export const checkIfAnyParamsExists = ({ categoryId, brandId, modelId }) => {
  if (
    categoryId === undefined &&
    brandId === undefined &&
    modelId === undefined
  ) {
    return true;
  }
  return false;
};
