import { Box, Grid } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Typography } from 'antd';
import GMaps from 'components/maps';
import { hexagonStructureStyle } from 'constants/styles';
// import ReplyIcon from '@mui/icons-material/Reply';
import { useLocation, useNavigate } from 'react-router-dom';
import { formImageUrl } from 'utils/brands';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DirectionsIcon from '@mui/icons-material/Directions';
import LanguageIcon from '@mui/icons-material/Language';

const RequestCreation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let { createdDeal, latitude, longitude, isHomeCollect, stores } =
    location.state || {};

  const navigateBackToHome = useCallback(
    (shouldDeleteHistory: Boolean) => {
      if (shouldDeleteHistory) {
        navigate('/', { replace: true });
        const handlePopState = () => {
          navigate('/', { replace: true });
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      } else {
        // navigate('/device');
      }
      return;
    },
    [navigate]
  );

  // isHomeCollect = false;
  useEffect(() => {
    if (!createdDeal) {
      navigateBackToHome(true);
    }
  }, [createdDeal, navigateBackToHome]);

  const dealAttribute = useMemo(() => {
    return createdDeal?.device?.attributes
      ? Object?.values(createdDeal?.device?.attributes)
      : [];
  }, [createdDeal]);

  return (
    <>
      <div
        className={`mx-auto w-[100%] ${isHomeCollect ? 'xl:w-[60%]' : 'xl:w-[80%]'} !text-center`}
      >
        {createdDeal?.device && (
          <>
            <Box sx={hexagonStructureStyle}>
              <DoneIcon
                sx={{ color: 'white', fontSize: '30px', fontWeight: '700' }}
              />{' '}
              {/* Centered icon */}
            </Box>
            <Box>
              <Typography className="!font-semibold !text-[1.1rem] md:!text-[1.3rem]">
                Amazing!
              </Typography>
            </Box>
            <Box>
              <Typography className="!font-[400] !text-primaryGrey !text-[0.9rem] md!text-[1rem]">
                You have successfully created a request.
              </Typography>
            </Box>
            <Box>
              <Typography className="!font-[400] !text-primaryGrey !text-[0.9rem] md!text-[1rem]">
                Our team will get back to you soon
              </Typography>
            </Box>
            <Box>
              <Typography className="!font-bold md:!text-[1.2rem] !text-[1.1rem]">
                Deal ID: {createdDeal?.dealNumber}
              </Typography>
            </Box>
          </>
        )}
        <Grid
          container
          columnGap={2}
          rowGap={2}
          className="!flex justify-center !text-left px-4 mt-6 lg:px-20 xl:!px-0"
        >
          <Grid md={isHomeCollect ? 7 : 5} xs={12}>
            <Box className="px-6 pb-2 pt-4 border-2 rounded-[20px]   border-white bg-[#f4f5f7]">
              <Typography className="!text-[#9da4b8] md:!text-[1rem] !text-[0.8rem] ">
                SUMMARY
              </Typography>
              <Box>
                <Typography className="!text-primaryBlue xl:!text-[1.4rem] md:!text-[1.3rem] text-[1.2rem] !font-bold">
                  {createdDeal?.price}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={`!text-[black] lg:!text-[1.1rem] ${isHomeCollect ? '!text-[1.rem]' : '!text-[1.1rem]'}  !font-[500]`}
                >
                  {createdDeal?.customer}
                </Typography>
              </Box>
              <Box
                className={`flex mt-4 ${isHomeCollect ? 'py-6' : 'py-9'} border-t-2 border-t-[primaryBlue]`}
              >
                <img
                  alt={'device'}
                  className={`md:!w-[140px] ${isHomeCollect ? 'md:!h-[150px]' : 'md:!h-[160px]'} !w-[100px] !h-[150px] object-cover !rounded-[10px]`}
                  src={formImageUrl(createdDeal?.device?.image)}
                ></img>
                <Box className="ml-4">
                  <Typography className="md:!text-[1.2rem] !text-[1rem] !font-semibold">
                    {createdDeal?.device?.name}
                  </Typography>
                  <Grid container columnGap={1} rowGap={2}>
                    {dealAttribute?.map((value: any) => {
                      return (
                        <Grid className="!text-[0.8rem] xl:!text-[0.9rem]  mt-2 p-2 bg-[#e7e7e7] rounded-[50px] text-center">
                          {value}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
            <Box
              className={'hidden lg:block cursor-pointer'}
              onClick={() => navigateBackToHome(true)}
            >
              <Typography className="!text-[1rem] mt-2 !font-semibold !text-primaryBlue">
                Back To Home
              </Typography>
            </Box>
          </Grid>

          <Grid md={isHomeCollect ? 4 : 5} xs={12} className="mb-4 ">
            {isHomeCollect ? (
              <Box className="md:!h-[91%] !p-[0px] border-2 rounded-[20px] border-white">
                <GMaps
                  Latitude={latitude || 0}
                  Longitude={longitude || 0}
                  height={'100%'}
                  className=" w-full lg:h-[100%] h-[32vh]  rounded-[20px] overflow-hidden"
                ></GMaps>
                {/* <Box className={'!w-[100%] hidden lg:block '}>
                  <Typography className="!text-[1rem] xl:!ml-[10px] mt-2 !font-semibold !text-right !text-primaryBlue">
                    <ReplyIcon className="scale-x-[-1] mb-[4px]" /> Share
                  </Typography>
                </Box> */}
              </Box>
            ) : (
              <>
                {stores?.map((data: any) => {
                  return (
                    <Box key={data.id} className="store-container">
                      <Typography className="!text-[1.1rem] !font-semibold">
                        Store Drop-off
                      </Typography>
                      <img
                        src={formImageUrl(data.image)}
                        alt="store"
                        className="my-2 h-[120px] w-[280px] rounded-[10px]"
                      />

                      <Box className="mt-2">
                        <Typography className="!text-[1.1rem] !font-bold">
                          Store Name
                        </Typography>

                        <Box className="flex flex-column justify-between mb-2 mt-2">
                          <Box className="flex flex-column items-center">
                            <Box className="text-center">
                              <LocationOnIcon
                                className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2rem]"
                                fontSize="medium"
                              />
                            </Box>
                            <Typography className="max-w-[90%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                              {data.address}
                            </Typography>
                          </Box>
                          <Box className="text-end flex flex-column items-center">
                            <DirectionsIcon className="text-primaryBlue text-base !text-[2rem] md:!text-[2rem]" />
                          </Box>
                        </Box>

                        <Box className="flex flex-column justify-between mb-2">
                          <Box className="flex flex-column items-center">
                            <Box className="text-center">
                              <PhoneAndroidIcon
                                className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2rem]"
                                fontSize="large"
                              />
                            </Box>
                            <Typography className="max-w-[80%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                              {data.phoneNumber}
                            </Typography>
                          </Box>
                          <Box className="text-end">
                            <CallIcon
                              fontSize="large"
                              className="text-primaryBlue text-base !text-[2rem] md:!text-[2rem]"
                            />
                          </Box>
                        </Box>

                        <Box className="flex flex-column justify-between mb-2">
                          <Box className="flex flex-column items-center">
                            <Box className="text-center">
                              <AccessTimeIcon
                                className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2rem]"
                                fontSize="large"
                              />
                            </Box>
                            <Typography className="max-w-[100%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                              9 AM to 10 PM (Monday to Saturday)
                            </Typography>
                          </Box>
                        </Box>

                        <Box className="flex flex-column items-center mb-2">
                          <Box className="flex flex-column md:mr-10 mr-1 items-center">
                            <Box className="text-center">
                              <AlternateEmailIcon
                                className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2rem]"
                                fontSize="large"
                              />
                            </Box>
                            <Typography className="max-w-[100%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                              info@store.com
                            </Typography>
                          </Box>
                          <Box className="flex flex-column !text-center items-center">
                            <Box className="text-center">
                              <LanguageIcon
                                className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2rem]"
                                fontSize="large"
                              />
                            </Box>
                            <Typography className="max-w-[100%] !ml-2 !text-center !text-[0.9rem] md:!text-[1rem]">
                              info@store.com
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {/* <Box className={'!w-[100%] hidden lg:block '}>
                        <Typography className="!text-[1rem] xl:!ml-[10px] mt-0 !font-semibold !text-right !text-primaryBlue">
                          <ReplyIcon className="scale-x-[-1] mb-[4px]" /> Share
                        </Typography>
                      </Box> */}
                    </Box>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
        <Box className="lg:hidden ">
          <Box
            className="!border-b-[2px]  !border-[primaryGrey] pb-4 cursor-pointer "
            onClick={() => navigateBackToHome(true)}
          >
            <Typography className="!text-primaryBlue !font-bold !text-[1.1rem]">
              Back to Home
            </Typography>
          </Box>
          {/* <Box className="pt-4 pb-4 px-4">
            <button
              type="submit"
              className="bg-primaryBlue !text-white !font-[500] !w-[100%] p-2 md:p-3 rounded-[10px] !text-[1.1rem]"
            >
              <ReplyIcon className="scale-x-[-1] mb-[4px] mr-2" />
              Share
            </button>
          </Box> */}
        </Box>
      </div>
    </>
  );
};
export default React.memo(RequestCreation);
