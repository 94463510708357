import { Box, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import getLocation, { FormAddress } from 'utils/geoLocation';
// import ShopImage from '../../assets/ShopImage.jpeg';
import { Select } from 'antd';
import DirectionsIcon from '@mui/icons-material/Directions';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import GMaps from 'components/maps';
import { GlobalSelectStyle, StyleSelect } from 'constants/styles';
import NavBar from 'components/navigation';
import TradeInHeader from '../../components/header/trade';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { formCreateRequestData } from 'mappers';
import { getStores, initiateRequestGeneration } from 'api/device/get';
import PickUp from 'layout/pickup';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { pickUpLocations } from '../../constants/index';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LanguageIcon from '@mui/icons-material/Language';
import useBrandAndModel, { BRAND_TYPE } from 'customHooks';
import { formImageUrl } from 'utils/brands';
// import { locations } from 'constants';

const { Option } = Select;

const GlobalStyles = GlobalSelectStyle;

const StyledSelect = StyleSelect;

export default function Collection() {
  const navigate = useNavigate();
  const [stores, setStores] = useState<any>([]);
  const {
    // brandData,
    // modelData,
    setProperty
    // attributesData,
    // brandError,
    // modelError,
    // attributeMetaData,
    // loading
  } = useBrandAndModel();
  useEffect(() => {
    const fetchStores = async () => {
      setProperty(BRAND_TYPE.loading, true);
      try {
        const { data } = await getStores();
        setStores(data || []);
      } catch (error) {
        console.error('Failed to fetch stores:', error);
      } finally {
        setProperty(BRAND_TYPE.loading, false);
      }
    };

    fetchStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const { selectedCondition, freeQuoteId, user } = location.state || {};
  const [geoLocation, setGeoLocation] = useState({
    latitude: 0,
    longitude: 0
  });
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [locationType, setLocationType] = useState<string>(
    pickUpLocations.Home
  );

  const fetchAddress = async ({ lat, long, isAddressType, address }: any) => {
    if (lat && long)
      setGeoLocation({ latitude: Number(lat), longitude: Number(long) });
    const { locationFromLatLong, locationFromAddress } = await getLocation();
    let result;
    if (!isAddressType) {
      result = await locationFromLatLong(lat, long);
      let [selectedCurrentLocation] = FormAddress([result]);
      setSelectedValue(selectedCurrentLocation.label);
    } else {
      result = await locationFromAddress(address);
    }
    return result;
  };

  const handleLocationFetch = useCallback(
    async (isAddressType: Boolean, address?: string) => {
      setOptions([]);

      try {
        if (!isAddressType)
          navigator.geolocation.getCurrentPosition(
            async pos => {
              const { latitude, longitude } = pos.coords;
              let result: any = await fetchAddress({
                lat: latitude,
                long: longitude,
                isAddressType: isAddressType
              });
              let [addressOptionResult]: any = FormAddress([result]);

              setOptions([addressOptionResult]);
            },
            error => {
              console.error('Error getting location:', error);
            }
          );
        else {
          let result = await fetchAddress({
            isAddressType: isAddressType,
            address: address
          });
          let addressOptionResult = FormAddress(result);
          setOptions(addressOptionResult);
        }
      } catch (error) {
        setOptions([]);
        setGeoLocation({ latitude: 0, longitude: 0 });
        console.error(error, 'Error while getting address');
      }
    },
    []
  );
  let [options, setOptions] = useState<any>([]);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (inputValue) {
      handleLocationFetch(true, inputValue);
    }
  }, [inputValue, handleLocationFetch]);

  const handleOptionSelect = (event: any) => {
    let { geoLocation, addressVal } = JSON.parse(event);
    setSelectedValue(addressVal);
    let parsedLocation = geoLocation;
    if (parsedLocation?.location) {
      let { location } = parsedLocation;
      setGeoLocation({
        latitude: Number(location?.lat) || 0,
        longitude: Number(location?.lng) || 0
      });
    }
  };

  const handleSubmission = async () => {
    if (geoLocation.latitude && geoLocation.longitude) {
      try {
        let isHomeCollect =
          locationType === pickUpLocations.Home ? true : false;
        const formattedData = formCreateRequestData({
          selectedValue,
          selectedCondition,
          user,
          geoLocation,
          isHomeCollect,
          stores
        });
        const { data } = await initiateRequestGeneration(
          formattedData,
          freeQuoteId
        );
        if (data?.device) {
          navigate('/create-request', {
            state: {
              createdDeal: data,
              latitude: geoLocation.latitude,
              longitude: geoLocation.longitude,
              isHomeCollect,
              stores
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error('Please select location', {
        autoClose: 3000
      });
    }
  };

  const pickUpLocationSet = useCallback(
    (type: string) => {
      setLocationType(type);
      if (type === pickUpLocations.Home) {
        setGeoLocation({
          latitude: 0,
          longitude: 0
        });
        setSelectedValue(null);
      } else {
        if (stores?.length) {
          let [firstStore] = stores;
          setGeoLocation({
            latitude: firstStore?.location.latitude,
            longitude: firstStore?.location.longitude
          });
          setSelectedValue(firstStore?.address || '');
        } else {
          setGeoLocation({
            latitude: 0,
            longitude: 0
          });
          setSelectedValue(null);
        }
      }
    },
    [setLocationType, stores]
  );

  return (
    <>
      <TradeInHeader />

      <NavBar></NavBar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
        className="flex justify-center !mt-10"
      >
        <Grid
          container
          rowGap={2}
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
          className="flex md:!justify-center max-w-[300px] md:min-w-[500px] border-t-[2px] py-4  border-[primaryGrey]"
        >
          <Grid item xs={12}>
            <Typography className="!font-bold md:!text-[1.3rem] py-4">
              Collection
            </Typography>
            <Box className="mb-4">
              <Box>
                <PickUp
                  pickUpLocationSet={pickUpLocationSet}
                  locationType={locationType}
                />
              </Box>
              {locationType === pickUpLocations.Store ? (
                <Box className="mb-6 border-b border-[#e5e7eb]">
                  {stores.length ? (
                    stores.map((data: any) => {
                      return (
                        <Box key={data.id} className="store-container">
                          <Typography className="!text-[1.1rem] !font-semibold">
                            Store Drop-off
                          </Typography>
                          <img
                            src={formImageUrl(data.image)}
                            alt="store"
                            className="my-4 h-[160px] w-[280px] rounded-[10px]"
                          />

                          <Box className="mt-8">
                            <Typography className="!text-[1.2rem] !font-bold">
                              Store Name
                            </Typography>

                            <Box className="flex flex-column justify-between mb-6 mt-4">
                              <Box className="flex flex-column items-center">
                                <Box className="text-center">
                                  <LocationOnIcon
                                    className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2.5rem]"
                                    fontSize="large"
                                  />
                                </Box>
                                <Typography className="max-w-[90%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                                  {data.address}
                                </Typography>
                              </Box>
                              <Box className="text-end flex flex-column items-center">
                                <DirectionsIcon className="text-primaryBlue text-base !text-[2rem] md:!text-[2.5rem]" />
                              </Box>
                            </Box>

                            <Box className="flex flex-column justify-between mb-6">
                              <Box className="flex flex-column items-center">
                                <Box className="text-center">
                                  <PhoneAndroidIcon
                                    className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2.5rem]"
                                    fontSize="large"
                                  />
                                </Box>
                                <Typography className="max-w-[80%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                                  {data.phoneNumber}
                                </Typography>
                              </Box>
                              <Box className="text-end">
                                <CallIcon
                                  fontSize="large"
                                  className="text-primaryBlue text-base !text-[2rem] md:!text-[2.5rem]"
                                />
                              </Box>
                            </Box>

                            <Box className="flex flex-column justify-between mb-6">
                              <Box className="flex flex-column items-center">
                                <Box className="text-center">
                                  <AccessTimeIcon
                                    className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2.5rem]"
                                    fontSize="large"
                                  />
                                </Box>
                                <Typography className="max-w-[100%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                                  9 AM to 10 PM (Monday to Saturday)
                                </Typography>
                              </Box>
                            </Box>

                            <Box className="flex flex-column items-center mb-6">
                              <Box className="flex flex-column md:mr-10 mr-1 items-center">
                                <Box className="text-center">
                                  <AlternateEmailIcon
                                    className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2.5rem]"
                                    fontSize="large"
                                  />
                                </Box>
                                <Typography className="max-w-[100%] !ml-2 !text-[0.9rem] md:!text-[1rem]">
                                  info@store.com
                                </Typography>
                              </Box>
                              <Box className="flex flex-column !text-center items-center">
                                <Box className="text-center">
                                  <LanguageIcon
                                    className="text-[#1f5cf7] text-base !text-[2rem] md:!text-[2.5rem]"
                                    fontSize="large"
                                  />
                                </Box>
                                <Typography className="max-w-[100%] !ml-2 !text-center !text-[0.9rem] md:!text-[1rem]">
                                  info@store.com
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <Box>
                      <Typography>No Stores Found</Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <>
                  <Box display="flex">
                    <GlobalStyles />
                    <StyledSelect
                      // open={true}
                      onChange={e => handleOptionSelect(e)}
                      value={selectedValue} // Controlled value
                      showSearch
                      placeholder={
                        <span className="text-[#ccc8c8] !text-[1rem] pl-3">
                          Location
                        </span>
                      }
                      onSearch={e => setInputValue(e)}
                      filterOption={false} // Disable built-in filtering
                      style={{ width: '100%' }}
                      dropdownRender={menu => <>{menu}</>}
                    >
                      {options.map((option: any) => (
                        <Option
                          key={option.value}
                          // onClick={() => handleOptionSelect(option.geometry)}
                          value={JSON.stringify({
                            geoLocation: option.geoLocation,
                            addressVal: option.label
                          })}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </StyledSelect>
                    <span
                      onClick={e => {
                        e.stopPropagation(); // Prevent triggering select dropdown on click
                        handleLocationFetch(false);
                      }}
                      className="cursor-pointer flex items-center relative right-[36px]"
                    >
                      <LocationSearchingIcon
                        className="!text-primaryBlue !cursor-pointer"
                        style={{ fontSize: 19 }}
                      />
                    </span>
                  </Box>
                  <GMaps
                    Latitude={geoLocation.latitude}
                    Longitude={geoLocation.longitude}
                    height={'38vh'}
                    className=" my-8 w-full rounded-[20px] overflow-hidden border-2 border-[#dedfe1]"
                  />
                </>
              )}
            </Box>
            <Box>
              <button
                type="submit"
                onClick={handleSubmission}
                className="bg-primaryBlue !text-white !w-[100%] p-2 rounded-[10px] !text-[0.9rem] md:!text-[1.1rem]"
              >
                Confirm
              </button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
