import { Checkbox, Box, Grid } from '@mui/material';
import { Typography } from 'antd';
import { pickUpLocations } from '../../constants/index';
import { PickUpSelectStyle } from 'constants/styles';

interface IPickUpProps {
  pickUpLocationSet: (type: string) => void;
  locationType: string;
}
export default function PickUp(props: IPickUpProps): JSX.Element {
  let { locationType, pickUpLocationSet } = props;
  return (
    <>
      <Grid container className="justify-between mb-10">
        <Grid xs={5.5}>
          <Box
            className={`flex direction-column  bg-${locationType !== pickUpLocations.Home ? 'white' : 'primaryBlue'} py-3 border-[1px] md:pl-[8px] rounded-[10px] items-center`}
          >
            {' '}
            <Checkbox
              checked={locationType === pickUpLocations.Home}
              onClick={() => pickUpLocationSet(pickUpLocations.Home)}
              sx={PickUpSelectStyle(locationType === pickUpLocations.Home)}
            />
            <Typography
              className={`text-${locationType !== pickUpLocations.Home ? 'primaryBlue' : 'white'}`}
            >
              Home Pickup
            </Typography>
          </Box>
        </Grid>
        <Grid xs={5.5}>
          <Box
            className={`flex direction-column bg-${locationType !== pickUpLocations.Store ? 'white' : 'primaryBlue'} py-3 border-[1px] md:pl-[8px] rounded-[10px] items-center`}
          >
            {' '}
            <Checkbox
              checked={locationType === pickUpLocations.Store}
              onClick={() => pickUpLocationSet(pickUpLocations.Store)}
              sx={PickUpSelectStyle(locationType === pickUpLocations.Store)}
            />
            <Typography
              className={`text-${locationType !== pickUpLocations.Store ? 'primaryBlue' : 'white'}`}
            >
              Store Dropoff
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
