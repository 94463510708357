const Apitypes = {
  category: '/customer/category',
  brandCategory: '/customer/brand-by-categoryid/',
  brandModel: '/customer/model/',
  attribute: '/customer/by-model/',
  quote: '/quote/',
  brandByCategory: '/customer/brand-by-categoryid/',
  requestGeneration: '/deal/',
  enquiry: '/customer/enquiry/',
  stores: '/customer/stores'
};

export default Apitypes;
