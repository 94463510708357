import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import React, { useCallback, useRef, useState } from 'react';

const GMaps = ({ Latitude, Longitude, height, className }) => {
  const mapContainerStyle = {
    height: height,
    width: '100%',
    filter: 'grayscale(20%)',
    borderRadius: '50px !important'
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: true
  };

  const mapRef = useRef();
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(true); // State for controlling InfoWindow visibility

  const onMapLoad = useCallback(map => {
    mapRef.current = map;
  }, []);

  const libraries = ['places'];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GEO_CODE_SECRETS, // Replace 'xxxx' with your actual API key
    libraries
  });

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps...';

  return (
    <div className={className}>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={{
          lat: Latitude,
          lng: Longitude
        }}
        options={options}
        onLoad={onMapLoad}
      >
        <MarkerF
          position={{
            lat: Latitude,
            lng: Longitude
          }}
          onClick={() => setIsInfoWindowOpen(!isInfoWindowOpen)} // Toggle InfoWindow on Marker click
        />
      </GoogleMap>
    </div>
  );
};

export default GMaps;
