export const defaultNavigationPage = 'Device';

export const pickUpLocations = { Home: 'Home', Store: 'Store' };

export const CardLayoutArrayConst = [
  {
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
  },
  {
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
  },
  {
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
  },
  {
    name: '',
    icon: '',
    status: '',
    id: '',
    _id: '',
    image: ''
  }
];
