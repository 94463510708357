import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import { DeviceCategory } from 'api/device/get';
import { categoryType } from '../../types/categoryType';
import Apitypes from 'api/constant';
import slugify from 'slugify';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { formImageUrl } from 'utils/brands';
import { CardLayoutArrayConst } from '../../../src/constants';
import CardLoader from 'layout/loader/card';

const DeviceSelectionLayout = () => {
  const navigate = useNavigate();
  const [deviceCategory, setDeviceCategory] =
    useState<categoryType[]>(CardLayoutArrayConst);
  const [isLoading, setIsLoading] = useState(false);

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1440 // Custom breakpoint for screens 1440px and above
      }
    }
  });

  useEffect(() => {
    const fetchDeviceCategory = async () => {
      try {
        setIsLoading(true);
        let { data } = await DeviceCategory(`${Apitypes['category']}?sortBy=name`);
        setDeviceCategory(data);
        setIsLoading(false);
      } catch (error) {
        setDeviceCategory([]);
        setIsLoading(false);
        console.error('Error fetching device category:', error);
      }
    };

    fetchDeviceCategory();
  }, []);

  const handleDeviceClick = (deviceType: string, categoryId: string) => {
    const sluggedDeviceType = slugify(deviceType, { lower: true });

    const path = `/device/${sluggedDeviceType}`;

    navigate(path, {
      state: {
        categoryId: categoryId
      }
    });
  };

  return (
    <Box className="mx-auto max-w-6xl px-4 md:px-0 md:mx-0">
      <Box className="flex md:justify-center justify-start !mb-6">
        <Typography className="!font-[600] !tracking-wide !text-[1.1rem] md:!text-[1.4rem] ">
          Select Device
        </Typography>
      </Box>
      {!deviceCategory?.length && (
        <Typography className="!font-[600] !tracking-wide !text-[1.1rem] md:!text-[1.2rem] !px-[0px] md:!text-center">
          No Category Data Found
        </Typography>
      )}
      <ThemeProvider theme={theme}>
        <Grid container spacing={2} rowGap={2} columnGap={0}>
          {deviceCategory.map((data: categoryType) => {
            return (
              <Grid item md={3} lg={2} xs={4}>
                <div onClick={() => handleDeviceClick(data?.name, data?.id)}>
                  <Card
                    className="!rounded-[16px] !p-[0px] !w-[100%] lg:!w-[92%] xl:!w-[100%] !bg-[#f7f9fa] !border-3 !border-white !border-solid   cursor-pointer"
                    sx={{ boxShadow: 'none', border: '3px solid white' }} // Removes the default shadow
                  >
                    {isLoading ? (
                      <CardLoader type={'card'} />
                    ) : (
                      <>
                        <CardContent className=" !py-[0px] !px-[0px] flex !justify-center">
                          <img
                            alt={`${data?.name}`}
                            className="md:!w-[140px] md:!h-[76px] xl:!h-[100px] !w-[100%] !h-[60px] object-contain"
                            src={formImageUrl(data?.image)}
                          ></img>
                          {/* <div className="md:!w-[140px] md:!h-[140px] !w-[100%] !h-[60px] object-contain">
                    {data.icon}
                  </div> */}
                        </CardContent>
                        <CardActions className="flex justify-center items-center !pt-0">
                          <Typography className="!font-medium !text-center !text-primaryBlack !text-[0.66rem] md:!text-[1.1rem]  !mb-2">
                            {data?.name}
                          </Typography>
                        </CardActions>
                      </>
                    )}
                  </Card>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </ThemeProvider>
    </Box>
  );
};

export default React.memo(DeviceSelectionLayout);
