import { CardActions, CardContent, Skeleton, Typography } from '@mui/material';
import React from 'react';

interface Iskeletal {
  height?: number;
  width?: number;
  variant?: 'rounded' | 'text' | 'rectangular' | 'circular' | undefined;
  className?: string;
  type?: string;
}
const SkeletalLoader: React.FC<Iskeletal> = ({
  type,

  variant,
  className
}) => {
  if (type === 'card') {
    return (
      <>
        <CardContent className=" !py-[0px] !px-[0px] flex !justify-center">
          <Skeleton variant="rounded" width={210} height={60} />
        </CardContent>
        <CardActions className="flex justify-center items-center !pt-0">
          <Typography className="!font-medium !text-center !text-primaryBlack !text-[0.66rem] md:!text-[1.1rem]  !mb-2">
            <Skeleton
              variant="rounded"
              className="!mt-[20px]"
              width={60}
              height={20}
            />
          </Typography>
        </CardActions>
      </>
    );
  } else {
    return <Skeleton variant={variant} className={className} />;
  }
};

export default SkeletalLoader;
